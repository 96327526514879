.form-items {
    display: flex;
    align-items: flex-end;

    .ui.scuf-dropdown-wrapper {
        width: 15% !important;
    }

    .serial {
        width: 15% !important; 
        .input-wrap {
         width: 100%;
 
             .input-box {
                 width: 100%;
             }
         }  
    }

    .problem {
       width: 33% !important; 

       .input-wrap {
        width: 100%;

            .input-box {
                width: 100%;
            }
        }
    }

    .button-upload {
        margin-bottom: 10px;
    }

    .button-add {
        margin-bottom: 10px;
        margin-left: 16px;
    }

}

.container-general-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 26px;
    padding: 16px;

    .search-items {
        display: flex;
        gap: 26px;
        align-items: flex-end;

        .ui.scuf-dropdown-wrapper {
            width: 35%;
        }
    }
    .table-items {
        max-width: 50%;

        th:nth-child(1), td:nth-child(1) {
            width: 30%;
        }
        th:nth-child(2), td:nth-child(2) {
            width: 60%; 
        }
        th:nth-child(3), td:nth-child(3) {
            width: 10%; 
        }
    }
}

.table-items-visit {
    max-width: 100%;
    padding: 20px;

    th:nth-child(1), td:nth-child(1) {
        width: 20%;
    }
    th:nth-child(2), td:nth-child(2) {
        width: 25%; 
    }
    th:nth-child(3), td:nth-child(3) {
        width: 50%; 
    }
    th:nth-child(4), td:nth-child(4) {
        width: 5%; 
    }
}

.container-admin {
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    gap: 40px;
}

.admin-column-1 {
    display: flex;
    flex-direction: column;
    width: 25%;

    .input-wrap {
        width: 90%;

        .input-box {
            width: 100%;
        }
    }
    
    .ui.scuf-dropdown-wrapper {
        width: 90% !important;
    }
}

.admin-column-2 {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input-obs {
    width: 100% !important;
        .input-wrap {
            width: 99%;

            .input-box {
                width: 100%;
            }
        }
}

.input-and-button {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-left: 0 !important;
}

.badge-total {
    display: flex;
    justify-content: center;
    margin: 1px;
    background-color: #ffc627;
    border-radius: 15px;
}
