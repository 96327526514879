.rating-component {
    .stars {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
  
      .icon {
        margin: 5px 10px; 
        margin-inline-start: 0;
      }
    }
  
    p {
      margin: 5px 0;
      font-size: 18px; 
      color: #303030; 
    }
  
    .description {
      font-weight: bold;
      color: #303030;
    }
  }
  