@import '@scuf/styles/variables.scss';
@import '@scuf/common/honeywell/theme.css';

.wrap-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.badge {
    text-transform: capitalize !important;
}

.grid-column {
    display: flex;
    flex-direction: column;
}

.info-title {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: 600;
}

.info-desc {
    margin-bottom: 10px;
}

.tile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
}

.column {
    display: flex;
    flex-direction: column;
}

.ui.single-input.disabled .input-wrap input[disabled] {
    cursor: not-allowed !important;
    background: #f7f7f7;
    color: black !important;
}

[class~=scuf-grid]>[class*=col-6] {
    .ui.scuf-dropdown-wrapper {
        width: 100%;
    }

    .ui.button.small {
        width: 100%;
        align-self: center;
        justify-self: center;
        height: 50%;

        .button-content {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.form4Columns {
    .single-input {
        width: 25%;
        padding: 10px;

        .input-wrap {
            width: 100%;

            .input-box {
                color: black !important;
                width: 100%;
            }
        }
    }

    .scuf-dropdown-wrapper {
        width: 33.33333%;

        .dropdown {
            width: 100%;
        }
    }
}

.form3Columns {
    padding: 10px;

    .ui.scuf-dropdown-wrapper {
        padding-left: 20px;
        padding-top: 10px;
    }

    .ui.scuf-dropdown-wrapper.SelectStyle {
        width: 100% !important;
    }

    .scuf-textarea-wrapper.textArea {
        width: 100% !important;
        padding: 10px;
    }

    .sc-bdVaJa.hVnMrm.h-icon.common {
        margin-top: 40px;
    }

    .ui.single-input {
        width: 33.33333%;
        padding: 10px;
    }

    .input-wrap {
        width: 100%;

        .input-box {
            width: 100%;
        }
    }
}

.form2Columns {
    width: 100% !important;
    padding: 10px;

    .ui.scuf-dropdown-wrapper.SelectStyle {
        width: 50% !important;
        padding: 10px;
    }

    .sc-bdVaJa.hVnMrm.h-icon.common {
        margin-top: 40px !important;
    }

    .ui.scuf-dropdown-wrapper.SelectStyle {
        width: 30% !important;
        padding: 10px;
    }

    .ui.single-input {
        padding: 10px;
        width: 30% !important;
    }
}

.scuf-dropdown-wrapper {
    width: 33.33333%;

    .dropdown {
        width: 100%;
    }
}

.scuf-textarea-wrapper {
    width: 50%;
}

.buttonsSearch {
    padding: 5px;
    display: flex;
    justify-content: flex-end;
}

.imageRapairCenter {
    width: 3000px !important;
    height: 200px !important;

    border: 0px !important;
}

.SelectStyle {
    padding: 10px;
    width: 25% !important;
}

.SelectStyle1 {
    padding: 10px;
    width: 90% !important;
}

.buttonsTop {
    height: 20%;
    width: 20%;
}

.div-alert-os {
    padding: 10px;
    background-color: rgba(243, 112, 33, 0.2);
    display: flex;
    border-radius: 10px;

    h5 {
        margin: auto 12px;
        color: $hcui-color-alert;
    }
}

.div-shipping-os {
    padding: 10px;
    background-color: hwb(239 18% 24% / 0.2);
    display: flex;
    border-radius: 10px;
    width: 100%;

    h5 {
        font-size: larger;
        margin-top: 0px;
        color: $hcui-color-info;
        margin-left: 20px;
        width: 100%;
    }
}

.container-search-os {
    margin-top: 15px;
}

.container-imagem {

    margin: auto !important
}

.div-datatable {
    width: 100%;
    margin-top: 15px;

    .forge-datatable-wrap {
        .p-datatable {
            .p-datatable-header {
                .filter-bar {
                    .search-output {
                        .single-input {
                            .input-wrap {
                                .input-box {
                                    height: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.div-datatable2 {
    display: flex;
    flex-direction: row;
}

.ChatTop {
    width: 100%;
    align-self: start;

    .h5 {
        align-items: center;
    }
}

.containerButton {
    display: flex;
    justify-content: flex-end;
    margin: 10px;
}

.send-button {
    height: fit-content;
    align-self: flex-start;
}

.container-chat1 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    background-color: $hcui-color-chrome-background;
    border-radius: 10px;

    .input-box {
        border-radius: 10px;
    }

    .chat-display {
        border-color: $hcui-color-separator;
        border-style: solid;
        border-radius: 10px;
        border-width: 1px;
        height: 70vh;
        overflow-y: auto;

        .sended-message,
        .received-message {
            margin-left: 20px;
            margin-right: 20px;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 16px;
            padding-bottom: 16px;
            border-radius: 10px;

            margin-top: 10px;
            margin-block-end: 10px;

            h5 {
                margin: 0px;
                padding: 5px;
            }

            .text-message {
                margin-bottom: 10px;
                background-color: white;
                padding: 10px;
                border-radius: 10px;
            }

            .header {
                text-align: center;
            }

            .date-message {
                font-size: 12px;
            }
        }

        .sended-message {
            text-align: right;
            background-color: $hcui-color-input-disabled;
            border-radius: 10px;
        }

        .received-message {
            background-color: $hcui-color-secondary-hover;
            border-radius: 10px;
        }
    }

    .container-chatSpareParts {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 15px;
        margin-bottom: 25vh;
        background-color: $hcui-color-chrome-background;
        border-radius: 10px;

        .input-box {
            border-radius: 10px;
        }

        .chat-display {
            border-color: $hcui-color-separator;
            border-style: solid;
            border-radius: 10px;
            border-width: 1px;
            height: 45vh;
            overflow-y: auto;

            .sended-message,
            .received-message {
                margin-left: 20px;
                margin-right: 20px;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 16px;
                padding-bottom: 16px;
                border-radius: 10px;

                margin-top: 10px;
                margin-block-end: 10px;

                h5 {
                    margin: 0px;
                    padding: 5px;
                }

                .text-message {
                    margin-bottom: 10px;
                    background-color: white;
                    padding: 10px;
                    border-radius: 10px;
                }

                .date-message {
                    font-size: 12px;
                }
            }

            .sended-message {
                text-align: right;
                background-color: $hcui-color-input-disabled;
                border-radius: 10px;
            }

            .received-message {
                background-color: $hcui-color-secondary-hover;
                border-radius: 10px;
            }
        }
    }

    .container-input {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .message-input {
            margin-right: 20px;
            width: 85%;

            .input-wrap {
                width: 100% !important;

                .input-box {
                    width: 100% !important;
                    border-radius: 10px;
                }
            }
        }
    }
}

.blue-text:hover {
    color: #10659f;
    cursor: pointer;
}

.container-events {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    margin-bottom: 25vh;
    background-color: $hcui-color-chrome-background;
    display: flex;

    .container-left {
        background-color: red;
        width: 30%;
        height: 100%;
    }

    .container-right {
        width: 40%;

        .container-event {
            margin-top: 15px;
            border-color: $hcui-color-separator;
            border-style: solid;
            border-width: 1px;

            .icon-event {
                position: absolute;
                margin-left: -30px;
                margin-top: 13px;
            }

            .header-event {
                background-color: $hcui-color-icon-disabled;
                padding: 15px;
            }

            .content-event {
                padding: 15px;

                .date-text {
                    font-weight: bold;
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.container-changes {
    .container-selects {
        .select {
            width: 50%;
        }
    }

    .container-text-area {
        margin-top: 10px;

        textarea {
            resize: none !important;
        }
    }

    .container-button {
        text-align: right;
    }
}

.grid-with-gap {
    gap: 20px;
}

.ui.scuf-dropdown-wrapper.select-type {
    width: 100% !important;

    .ui.selection.dropdown {
        width: 100% !important;
    }
}

.budget-wrapper {
    margin: 30px 18px;
}

.more-information-table {

    th,
    tr,
    td {
        margin: 20px !important;
        padding: 5px !important;
        font-size: .7cqw !important;
        vertical-align: middle !important;
    }

    td {
        font-size: .7cqw !important;
        font-weight: 600 !important;
    }
}

.big-centralized {
    font-size: 20px;
    text-align: center;
    margin: 20px 50px;
}

textarea {
    resize: none;
    width: 100% !important;
}

.scuf-textarea-wrapper {
    width: 100% !important;
    padding: 0.7% !important;
}

.table-center-aligned {

    td,
    th {
        text-align: center !important;
    }

    .badge {
        margin: 0 !important;
    }
}

.modalConsultWarantyLarge {
    .ui.striped.table {
        margin-top: 100;
    }

    .header {
        width: 100% !important;
        padding: 110px !important;
    }

    .modalHeader {
        padding-top: 120px;
        padding-left: 20px !important;
        width: 100%;
        height: 70px;
        background-color: $hcui-color-primary;
        border-radius: 10px;
        color: $hcui-color-text-fixed;
    }

    .content {
        padding: 20px !important;
    }

    .actions {
        padding: 20px !important;
    }
}

.modalW700 {
    height: auto !important;
    width: 1200px !important;
    padding: 20px !important;
    margin: 20px !important;
}

.table-OS {

    .p-datatable table {
        border-collapse: collapse;
        width: 100%;
        table-layout: auto;
    }

    .p-resizable-column {
        width: auto !important;
    }

    .p-column-title {
        white-space: nowrap;
        padding: 10px 0;
        max-width: calc(100% + 1.5rem) !important;
        text-align: left;
        overflow: visible;
    }

    .cell-data-wrap {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ui.forge-datatable-wrap .p-datatable .p-datatable-header .search-output .global-filter .search-icon {
        height: 3rem;
    }
}