@import '@scuf/styles/variables.scss';
$button-modules-color: $hcui-color-info;
$button-utilities-color: #73a9ad;
$button-admintools-color: #ff9551;

.header-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    h2 {
        margin: 0px;
    }
    h5 {
        margin: 0px;
        line-height: 35px;
    }
}
.container-parent {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    height: auto;
}
.container-dashboard {
    display: inline-block;
    width: 70%;
    border-radius: 10px;
    background-color: $hcui-color-chrome-background;
    padding: 30px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    margin-block-end: 30px;
}
.container2-dashboard {
    background-color: $hcui-color-chrome-background;
    padding: 30px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    display: inline-block;
    border-radius: 10px;
    margin-block-end: 30px;
    width: 30%;
    margin-left: 20px;
}
.inner-container-dashboard-modules {
    width: 100%;
    .button-menu {
        border-color: $button-modules-color !important;
        padding: 12px !important;
        margin: 10px 10px 0px 0px !important;
        min-width: 340px !important;
        .button-content {
            display: flex !important;
            color: $button-modules-color;
            i,
            p {
                float: left;
            }
        }
    }
    .button-menu:hover {
        background-color: $button-modules-color !important;
        .button-content {
            color: $button-inline-font-color;
        }
    }
}

.inner-container-dashboard-utilities {
    width: 100%;
    .button-menu {
        border-color: $button-utilities-color !important;
        padding: 12px !important;
        margin: 10px 10px 0px 0px !important;
        min-width: 340px !important;
        .button-content {
            display: flex !important;
            color: $button-utilities-color;
            i,
            p {
                float: left;
            }
        }
    }
    .button-menu:hover {
        background-color: $button-utilities-color !important;
        .button-content {
            color: $button-inline-font-color;
        }
    }
}

.inner-container-dashboard-admintools {
    width: 100%;
    .button-menu {
        border-color: $button-admintools-color !important;
        padding: 12px !important;
        margin: 10px 10px 0px 0px !important;
        min-width: 340px !important;
        .button-content {
            display: flex !important;
            color: $button-admintools-color;
            i,
            p {
                float: left;
            }
        }
    }
    .button-menu:hover {
        background-color: $button-admintools-color !important;
        .button-content {
            color: $button-inline-font-color;
        }
    }
}
.profile {
    width: 100%;
    display: flex;
    .icon-profile {
        display: inline-block;
        width: 100px;
        min-width: 70px;
        margin-top: -4px;
    }
    .info-profile {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 85%;
        padding-left: 10px;
        h3{
            margin-bottom: 0px;
            margin-top: 0px;
        }
        h2 {
            margin-bottom: 0px;
            padding: 0px;
        }
        h5 {
            margin: 0px;
        }
    }
}
.profile-info {
    h3 {
        margin: 0px;
    }
}
.search-container {
    .button {
        margin-top: 20px !important;
    }
}
