.container-general-items-img {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}

h2 {
    text-align: center;
    width: 100%;
}

.search-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    width: 100%;

    .ui.scuf-dropdown-wrapper {
        width: 30%;
    }

    .buttonFilter {
        align-items: start;
        margin-top: auto;
        margin-right: auto;
    }
}

.button-add {
    margin-left: auto;
    align-self: 50px;
}

.table-items-img {
    margin-bottom: 20px;
    width: 100%;
}

.form-group {
    margin-bottom: 20px;
    width: 100%;
}

.image-previews {
    display: flex;
    flex-wrap: nowrap;
    gap: 100px;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
    overflow-x: auto;
}

.image-preview {
    position: relative;
    width: 35%;
    height: 400px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;
}

.image-preview img {
    max-width: 100%;
    max-height: 100%;
}

.image-actions {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 5px;
}

.image-actions .scuf-icon {
    cursor: pointer;
    margin-right: 10px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

.image-actions .scuf-icon-edit {
    color: blue;
}

.image-actions .scuf-icon-delete {
    color: red;
}

.button-upload {
    align-self: center;
}
