.button-hsl {
    margin: 10px;
    display: flex;
    justify-content: end;
}

.ui.checkbox .box,
.ui.checkbox label {
    margin: 0 auto;
    padding-left: 0;
}

.ui.label.badge.grey {
    margin: 3px;
}

.icon-event {
    cursor: pointer;
}

.icon-event:hover {
    color: #1274b7;
    font-size: 130% !important;
}

.ui.page-header {
    z-index: 4 !important;
}

.hsl2Columns {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    padding-left: 8px;

    .ui.scuf-dropdown-wrapper {
        padding: 10px !important;
        width: 30% !important;
    }

    .date-picker-input {
        width: 30% !important;
    }
}

body>div.ui.page.modals.dimmer.transition.visible.active>div>div.scrolling.content>div:nth-child(2)>div.ui.scuf-dropdown-wrapper.select-pn {
    width: 25%;
    padding: 10px;
}

body>div.ui.page.modals.dimmer.transition.visible.active>div>div.scrolling.content>div:nth-child(3)>div.ui.scuf-dropdown-wrapper.select-type {
    width: 25% !important;
    padding: 10px;
}

body>div.ui.page.modals.dimmer.transition.visible.active>div>div.scrolling.content>div:nth-child(7)>div.ui.scuf-dropdown-wrapper.select-type {
    width: 25% !important;
    padding: 10px;
}

body>div.ui.page.modals.dimmer.transition.visible.active>div>div.scrolling.content>div:nth-child(2)>div.ui.scuf-dropdown-wrapper.select-type {
    width: 25% !important;
    padding: 10px;
}

.form4Columns {
    margin: 20px;

    .date-picker-input {
        width: 25% !important;

        .overlay-position-wrap {
            width: 100% !important;

            .datetime-input-wrap {
                width: 100% !important;

                .single-input {
                    width: 100% !important;
                }
            }
        }
    }

    .ui.scuf-dropdown-wrapper {
        padding: 10px !important;
        width: 25% !important;
    }
}

.form5Columns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .ui.scuf-dropdown-wrapper {
        width: 20% !important;
    }

    .ui.single-input.description {
        margin-left: 12px;
        width: 40% !important;
    }

    .ui.single-input,
    .ui.single-input.price.disabled {
        width: 13% !important;
    }

    .ui.medium.button.primary.button-add {
        margin: 27px 0 0 12px;
        width: 17% !important;
    }
}

.form6Columns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .ui.scuf-dropdown-wrapper {
        width: 20% !important;
    }

    .ui.single-input.description {
        width: 30% !important;
    }

    .ui.single-input {
        width: 10% !important;

        .overlay-position-wrap {
            width: 100% !important;

            .datetime-input-wrap {
                width: 100% !important;

                .single-input {
                    width: 100% !important;
                }
            }
        }
    }

    .ui.medium.button.primary.button-add {
        margin: 27px 0 0;
        width: 17% !important;
    }
}

.form3Columns.partnerQuotation {
    width: 150% !important;

    .input-label-message {
        font-size: 1.1rem !important;
    }

}

.divQuotation {
    margin: 10px 0 0 20px !important;
    padding: 0 5px 5px 12px;
    border: #d0d0d0 1px solid;
    border-radius: 2px !important;
}

.headerQuotation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: #d0d0d0 1px solid;

    .ui.page-header {
        border-bottom: none !important;
    }

}

.TableQuotation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.partsQuotation,
.footer {
    margin-left: 16px;
    width: 68.5%;
}

.footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 45px;
    padding: 5px 12px;
    border: #d0d0d0 1px solid;
    border-top: none;
    margin-bottom: 15px;
}

.footer span {
    margin-right: 10px;
    font-family: "Honeywell Sans Web", Arial, Helvetica, sans-serif;
    letter-spacing: 0px;
    font-weight: bold;
    font-size: .77rem;
    line-height: 1.25rem;
    color: #606060;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 1.5rem);
}

.buttonsQuotatios {
    margin: 30px 0 0 20px;
}

.p-datatable.p-component.partsUsed {
    width: 70% !important;
    margin: 0 0 15px 15px;
    max-height: 200px
}

.p-datatable-wrapper {
    .p-column-title {
        white-space: nowrap;
        padding: 10px 0;
        max-width: calc(100% + 1.5rem) !important;
        text-align: left
    }

}

.AddEquipment {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .Add,
    .Reset {
        margin-bottom: 10px;
    }

    .Add {
        margin-left: 15px;
    }
}

.Menu-vertical {
    display: flex;
    gap: 10px;
    align-items: center;
}

.Table-delivery {
    max-width: 100%;
    margin: 30px 20px;

    th:nth-child(1),
    td:nth-child(1) {
        width: 10%;
    }

    th:nth-child(2),
    td:nth-child(2) {
        width: 10%;
    }

    th:nth-child(3),
    td:nth-child(3) {
        width: 10%;
    }

    th:nth-child(4),
    td:nth-child(4) {
        width: 30%;
    }

    th:nth-child(5),
    td:nth-child(5) {
        width: 10%;
    }

    th:nth-child(6),
    td:nth-child(6) {
        width: 10%;
    }

    th:nth-child(7),
    td:nth-child(7) {
        width: 10%;
    }

    th:nth-child(8),
    td:nth-child(8) {
        width: 10%;
    }
}

.Table-inventory {
    max-width: 100%;
    margin: 30px 20px;

    th:nth-child(1),
    td:nth-child(1) {
        width: 5%;
    }

    th:nth-child(2),
    td:nth-child(2) {
        width: 25%;
    }

    th:nth-child(3),
    td:nth-child(3) {
        width: 10%;
    }

    th:nth-child(4),
    td:nth-child(4) {
        width: 10%;
    }

    th:nth-child(5),
    td:nth-child(5) {
        width: 30%;
    }

    th:nth-child(6),
    td:nth-child(6) {
        width: 8%;
    }

    th:nth-child(7),
    td:nth-child(7) {
        width: 5%;
    }

    th:nth-child(8),
    td:nth-child(8) {
        width: 5%;
    }
}

.Table-inventory-log {
    max-width: 100%;
    margin: 30px 20px;

    th:nth-child(1),
    td:nth-child(1) {
        width: 10%;
    }

    th:nth-child(2),
    td:nth-child(2) {
        width: 10%;
    }

    th:nth-child(3),
    td:nth-child(3) {
        width: 9%;
    }

    th:nth-child(4),
    td:nth-child(4) {
        width: 15%;
    }

    th:nth-child(5),
    td:nth-child(5) {
        width: 10%;
    }

    th:nth-child(6),
    td:nth-child(6) {
        width: 8%;
    }

    th:nth-child(7),
    td:nth-child(7) {
        width: 7%;
    }

    th:nth-child(8),
    td:nth-child(8) {
        width: 12%;
    }

    th:nth-child(9),
    td:nth-child(9) {
        width: 10%;
    }

    th:nth-child(10),
    td:nth-child(10) {
        width: 8%;
    }

    th:nth-child(11),
    td:nth-child(11) {
        width: 5%;
    }
}

.Table-repair {
    max-width: 100%;
    margin: 30px 20px;

    th:nth-child(1),
    td:nth-child(1) {
        width: 5%;
    }

    th:nth-child(2),
    td:nth-child(2) {
        width: 5%;
    }

    th:nth-child(3),
    td:nth-child(3) {
        width: 15%;
    }

    th:nth-child(4),
    td:nth-child(4) {
        width: 15%;
    }

    th:nth-child(5),
    td:nth-child(5) {
        width: 10%;
    }

    th:nth-child(6),
    td:nth-child(6) {
        width: 10%;
    }

    th:nth-child(7),
    td:nth-child(7) {
        width: 8%;
    }

    th:nth-child(8),
    td:nth-child(8) {
        width: 10%;
    }

    th:nth-child(9),
    td:nth-child(9) {
        width: 10%;
    }

    th:nth-child(10),
    td:nth-child(10) {
        width: 9%;
    }

    th:nth-child(11),
    td:nth-child(11) {
        width: 5%;
    }
}

.Table-contract {
    max-width: 100%;
    margin: 30px 20px;

    th:nth-child(1),
    td:nth-child(1) {
        width: 9%;
    }

    th:nth-child(2),
    td:nth-child(2) {
        width: 9%;
    }

    th:nth-child(3),
    td:nth-child(3) {
        width: 5%;
    }

    th:nth-child(4),
    td:nth-child(4) {
        width: 15%;
    }

    th:nth-child(5),
    td:nth-child(5) {
        width: 15%;
    }

    th:nth-child(6),
    td:nth-child(6) {
        width: 10%;
    }

    th:nth-child(7),
    td:nth-child(7) {
        width: 10%;
    }

    th:nth-child(8),
    td:nth-child(8) {
        width: 10%;
    }

    th:nth-child(9),
    td:nth-child(9) {
        width: 5%;
    }
}

.Table-product-parts {
    max-width: 100%;
    margin: 30px 20px;

    th:nth-child(1),
    td:nth-child(1) {
        width: 10%;
    }

    th:nth-child(2),
    td:nth-child(2) {
        width: 15%;
    }

    th:nth-child(3),
    td:nth-child(3) {
        width: 10%;
    }

    th:nth-child(4),
    td:nth-child(4) {
        width: 25%;
    }

    th:nth-child(5),
    td:nth-child(5) {
        width: 5%;
    }

    th:nth-child(6),
    td:nth-child(6) {
        width: 5%;
    }

    th:nth-child(7),
    td:nth-child(7) {
        width: 5%;
    }
}


.Table-parts-repair {
    margin: 30px 2px;
    max-height: 250px;
    width: 115% !important;

    th:nth-child(1),
    td:nth-child(1) {
        width: 5%;
    }

    th:nth-child(2),
    td:nth-child(2) {
        width: 10%;
    }

    th:nth-child(3),
    td:nth-child(3) {
        width: 10%;
    }

    th:nth-child(4),
    td:nth-child(4) {
        width: 25%;
    }

    th:nth-child(5),
    td:nth-child(5) {
        width: 10%;
    }

    th:nth-child(6),
    td:nth-child(6) {
        width: 10%;
    }

    th:nth-child(7),
    td:nth-child(7) {
        width: 10%;
    }

    th:nth-child(8),
    td:nth-child(8) {
        width: 5%;
    }
}

.buttonsSearch {
    padding-right: 24px;
}

.ui.forge-datatable-wrap .p-datatable .p-datatable-header .filter-bar.no-search,
.ui.forge-datatable-wrap .p-datatable .p-datatable-header .action-bar.no-search {
    justify-content: flex-start;
}

.ui.forge-datatable-wrap .p-datatable .p-datatable-header .filter-bar .header-bar-item,
.ui.forge-datatable-wrap .p-datatable .p-datatable-header .action-bar .header-bar-item {
    color: #1274B7;
}

.ui.scuf-dropdown-wrapper.expanded {
    z-index: 5;
}