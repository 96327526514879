@import '@scuf/styles/variables.scss';

.ui.raised.card.card-with-border {
    border-radius: 10px !important;

    .content {
        border: #d0d0d0 0.0625rem solid !important;
    }
}

.grid-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0px 12px;


}

.carousel {
    margin-bottom: 20px;
}

.img-carousel {
    height: 100% !important;
    width: auto !important;
}

@media screen and (min-width: 500px) {
    .carousel {
        height: 350px !important;
    }
}

@media screen and (min-width: 1200px) {
    .carousel {
        height: 500px !important;
    }
}


.container {
    width: 95vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: #ffff;
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    margin-block-end: 30px;
    border-radius: 10px;

    h2,
    h4 {
        text-transform: uppercase;
        font-weight: 600;
    }
}

.container-peq {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: #ffff;
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    margin-block-end: 30px;
    border-radius: 10px;

    h2 {
        font-weight: 500;
        letter-spacing: 0px;
        word-break: normal;
        tab-size: 4;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        font-family: "Honeywell Sans Web", "Helvetica Neue", "Arial", "sans-serif" !important;
        font-weight: 500;
        box-sizing: border-box;
        margin: 0em 0em 1em;
        line-height: 1.4285em;
    }

    h4 {
        text-transform: uppercase;
        font-weight: 600;
    }

    .h1 {
        font-family: "Honeywell Sans Web", Arial, Helvetica, sans-serif;
        color: #303030;
        letter-spacing: 0px;
        font-weight: 400;
        font-size: 20px;
    }
}

.container-med {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: #ffff;
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    margin-block-end: 30px;
    border-radius: 10px;

    h2 {
        font-weight: 500;
        letter-spacing: 0px;
        word-break: normal;
        tab-size: 4;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        font-family: "Honeywell Sans Web", "Helvetica Neue", "Arial", "sans-serif" !important;
        font-weight: 500;
        box-sizing: border-box;
        margin: 0em 0em 1em;
        line-height: 1.4285em;
    }

    h4 {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 20px;
        font-family: "Honeywell Sans Web", "Helvetica Neue", "Arial", "sans-serif" !important;
        color: #EE3123 !important;
        font: bold !important;
    }

    .h1 {
        font-family: "Honeywell Sans Web", Arial, Helvetica, sans-serif;
        letter-spacing: 0px;
        font-weight: 400;
        font-size: 20px;
    }
}

.container-grande {
    width: 95vw;
    margin-left: auto;
    margin-right: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: #ffff;
    padding: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    margin-block-end: 30px;
    border-radius: 10px;

    h2 {
        font-weight: 500;
        letter-spacing: 0px;
        word-break: normal;
        tab-size: 4;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        font-family: "Honeywell Sans Web", "Helvetica Neue", "Arial", "sans-serif" !important;
        font-weight: 500;
        box-sizing: border-box;
        margin: 0em 0em 1em;
        line-height: 1.4285em;
    }

    h4 {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 20px;
        font-family: "Honeywell Sans Web", "Helvetica Neue", "Arial", "sans-serif" !important;
        color: #EE3123 !important;
        font: bold !important;
    }

    .h1 {
        font-family: "Honeywell Sans Web", Arial, Helvetica, sans-serif;
        letter-spacing: 0px;
        font-weight: 400;
        font-size: 20px;
    }
}

.ui.page-footer.menu {
    margin: 0px !important;
}

.container {
    height: auto !important;
    margin-block-end: 30px;
}

.buttonExport {
    .teste12 {
        margin-left: 80% !important;
    }
}

.container-large {
    width: 99%;
    height: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: $hcui-shadow-color;
    padding: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    margin-block-end: 30px;
}

.container-large-color-icon {
    width: 99%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: $hcui-color-chrome-background;
    padding: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    margin-block-end: 30px;
    display: flex;
    border-radius: 10px;

    .h2-icon {
        padding-left: 20px;
        width: 100%;
    }
}

.container-large-color {
    width: 99%;
    height: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: $hcui-color-chrome-background;
    padding: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    margin-block-end: 30px;
    border-radius: 10px;
}

.cards-container-2 {
    display: flex;
    justify-content: space-between;

    a {
        padding: 10px !important;
        width: 49% !important;

        .card {
            // .header {
            //     // transition-property: font-size;
            //     // transition-duration: 500ms;
            // }

            // margin-bottom: 0px;
            // margin-top: 10px !important;
            .content {

                img {
                    width: 100%;
                    height: 150px;
                    object-fit: cover;
                }
            }
        }

        .footer {
            padding: 10px !important;
            border-top-color: red !important;
        }
    }
}

.InputText {
    padding: 20px;
    width: 30% !important;

    .ui.single-input {
        width: 100% !important;

        .input-wrap {
            width: 100% !important;
            border-radius: 10px;

            .input-box {
                width: 100% !important;
                border-radius: 10px;
            }
        }
    }
}

.NumbersPersonal {
    width: 100% !important;

    .sidebar {
        width: 25% !important;

        .ui.vertical.menu {
            width: 100% !important;

            .link.item {
                width: 100% !important;
            }
        }
    }
}

.accordion.ui.fluid {
    border-radius: 10px !important;

    .title {
        border-radius: 10px !important;
    }
}

.container {
    .ui.raised.card {
        border-radius: 10px !important;
        border: 1px solid rgba(0, 0, 0, 0.1) !important;

        .content {
            border-radius: 10px !important;
        }
    }
}

.ui.raised.card {
    .content {
        border-radius: 10px !important;
    }
}

.cards-container-3 {
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex !important;

    a {
        display: flex !important;
        width: 33% !important;
        min-width: 250px;

        .card {
            min-height: 400px;

            .header {
                transition-property: font-size;
                transition-duration: 500ms;
            }

            margin-top: 10px !important;
            margin-bottom: 0px;

            .content {
                img {
                    width: 100%;
                    height: 150px;
                    object-fit: cover;
                }
            }
        }
    }
}

.largeIcon {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    color: $button-primary-color;
}

.card:hover {
    cursor: pointer;

    .header {
        font-size: 25px;
        font-weight: bold;
    }

    .content {
        .largeIcon {
            color: $button-primary-color-focus;
        }
    }
}

span {
    .card:hover {
        cursor: default;

        .header {
            font-size: 18px;
        }
    }

    .card {
        .header {
            font-size: 18px;
            font-weight: bold !important;
        }

        .footer {
            margin-bottom: 10px !important;
        }
    }
}

.iconArrow {
    margin-left: 0px !important;
}

.footer-menu {
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

}

.miniContainerFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    margin-left: 10px;
    margin-right: 10px;

    ul {
        padding-left: 0px;
        margin: 0px;
        font-size: 12px;
        font-weight: 600;
    }

    h5 {
        margin: 0px;
    }

    ul {
        font-size: 10px;
    }

    a {
        font-size: 12px;
        font-weight: 600;
    }
}

.miniContainerFooter1 {
    display: flex;

    align-items: flex-end !important;
    // margin-left: 10px;
    // margin-right: 10px;
    width: 100%;
    padding-left: 20px;

    ul {

        //  margin: 20px !important;
        font-size: 12px;
        font-weight: 600;
    }

    h5 {
        margin: 0px;
    }

    a {
        font-size: 12px;
        font-weight: 600;
    }
}

.right.item {
    .copyright {

        padding-inline-start: 20px !important;
    }
}

.contentPageContainer {
    display: flex;
    border-radius: 20px !important;
    font-family: 'Honeywell Sans Web', 'Helvetica Neue', 'Arial', 'sans-serif' !important;
    font-size: 15px;

    img {
        padding: 20px;
        width: 20% !important;
        border-radius: 20px !important;
        // object-fit: cover;
        padding: 10px !important;
    }

    p {
        border-radius: 20px !important;
        margin-left: 10px;
        text-align: justify;
    }
}

.buttonsSignIn {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.buttonCancelSecondary {
    border-color: $hcui-color-error !important;
    color: $hcui-color-error !important;
}

.buttonCancelPrimary {
    border-color: $hcui-color-error !important;
    background-color: $hcui-color-error !important;
}

.form2Columns {
    .single-input {
        width: 50%;

        .input-wrap {
            width: 100%;

            .input-box {
                width: 100%;
            }
        }
    }
}

.form3Columns {
    .ui.scuf-dropdown-wrapper.SelectStyle {
        width: 30% !important;
    }

    width: 100% !important;

    .container-button1 {
        height: 0px;
        padding-left: 70% !important;
        width: 100% !important;
    }

    .ui.scuf-dropdown-wrapper {
        padding: 10px !important;
        width: 30% !important;
    }

    .single-input {
        width: 30% !important;

        .input-wrap {
            width: 100%;

            .input-box {
                width: 100%;
            }
        }
    }

    .date-picker-input {
        width: 30% !important;

        .overlay-position-wrap {
            width: 100% !important;

            .datetime-input-wrap {
                width: 100% !important;

                .single-input {
                    width: 100% !important;
                }
            }
        }
    }

    .scuf-dropdown-wrapper {
        width: 33.33333%;

        .dropdown {
            width: 100%;
        }
    }
}

.accordion {
    div {
        .content {
            button {
                margin-top: 10px;
            }
        }
    }
}

.modalCancel {
    width: 40% !important;
    min-width: 350px;
    padding: 20px !important;

    .actions {
        display: flex !important;
        justify-content: space-between !important;
        flex-wrap: wrap;

        .button {
            margin: 0px !important;
            margin-top: 10px !important;
        }
    }
}

.successInput {
    .input-wrap {
        .input-box {
            border-color: $hcui-color-success !important;
        }
    }

    .below-text {
        span {
            color: $hcui-color-success !important;
        }
    }
}

.col-sm-12 {
    padding: 100%;
    width: 100%;
}

.loadingIcon {
    margin-top: 35px !important;
}

.col-sm-4 {
    word-break: normal;
    tab-size: 4;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.87);
    background-repeat: no-repeat;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Honeywell Sans Web', 'Helvetica Neue', 'Arial', 'sans-serif' !important;
    font-weight: 500;
    padding: 12px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
    width: 33.33333333%;
}

.row {
    word-break: normal;
    tab-size: 4;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.87);
    background-repeat: no-repeat;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Honeywell Sans Web', 'Helvetica Neue', 'Arial', 'sans-serif' !important;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    margin: -12px;
    margin-right: -15px;
    margin-left: -15px;
    margin-top: 12px;
}

.caption-text {
    font-size: 18px;
    color: $button-primary-color;
}

.img-responsive {
    border-radius: 10px;
    border-left: 10%;
    border-right: 20%;
    margin-block-end: 10px;
    width: 80%;
}

.select-type {
    width: 420px !important;
}

.col-sm-12col-md-7col {
    flex: 1;
    max-width: 100%;
    margin-left: 10%;
    margin-right: 20px;
    margin-top: 10px;
}

.col {
    word-break: normal;
    tab-size: 4;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.87);
    background-repeat: no-repeat;
    margin-right: 5%;
    margin-left: 5%;
    box-sizing: border-box;
    font-family: 'Honeywell Sans Web', 'Helvetica Neue', 'Arial', 'sans-serif' !important;
    font-weight: 500;
    width: 100%;
    padding: 12px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.v-text-field__slot {
    flex: 1;
    max-width: 100%;
}

.container-selects {
    width: 100%;
    border-radius: 10px !important;
    padding: 10px !important;

    .ui.small.modal.transition.visible.active {
        width: 100% !important;
        padding: 0% !important;
    }
}

.modal {
    border: 0% !important;
    padding: 0%;
    margin: 0% !important;
    border-radius: 10% !important;
}

.modalConsultWaranty {
    .header {
        margin-block-end: 12px !important;
        width: 100% !important;
        padding: 0px !important;
    }

    .modalHeader {
        padding-top: 20px;
        padding-left: 20px;
        width: 100%;
        height: 70px;
        background-color: $hcui-color-primary;
        border-radius: 0px;
        color: $hcui-color-text-fixed;
    }

    .content {
        padding: 20px !important;
    }

    .actions {
        padding: 20px !important;
    }
}

.buttonModal {
    justify-content: flex-end;
    display: flex;
}

.message-initial {
    text-align: center;
    display: block;
    border-radius: 20px;
}

.Tabelas {
    flex: auto;
}

.Toastify__toast-container {
    padding: 0px !important;
}

.containerFindAssistence {
    width: 95%;
    height: 60%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: $hcui-shadow-color;
    padding: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    margin-block-end: 30px;
}

.notificationError {
    padding: 0px !important;

    .Toastify__toast-body {
        padding: 0px;

        div {
            .notification {
                margin: 0px;
            }
        }
    }
}

.Lista {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    background-color: $hcui-color-chrome-background;
    width: 100%;
    font-size: medium;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    padding: 10px !important;
    margin-block-end: 30px;
}

.default-text {
    font-size: 18px;
    font-weight: 500;
    font-family: $hcui-font-family;
}

.default-ul {
    font-size: 16px;
    font-weight: bold;

    li {
        list-style: none;
        list-style-type: decimal;
    }
}

.column-container {
    display: flex;
}

.column {
    flex: 1;
    padding: 0 10px;
}

.app-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    .body-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 40px;

        .row {
            flex-grow: 0;
            gap: 40px;
            align-items: flex-end;
            position: relative;
        }
    }
}

.clickable:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.top-pdf {
    width: 100% !important;
    background-color: grey !important;
    text-align: center !important;
}

.no-click {
    cursor: default !important;
}

.alin {
    text-align: justify !important;
}

.tab-with-divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;

    .alin {
        text-align: justify !important;
    }
}

.no-padding {
    padding: 0 !important;
}

.hidden {
    visibility: hidden !important;
}



.line {
    display: flex;
    flex-direction: row;
    width: 41%;
    height: 20px;
    border-bottom: 1px solid black;
    border-color: rgba(0, 0, 0, 0.12);
    text-align: start;
}

.line>span {
    background-color: #F3F5F6;
}

.resumeBarContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.space {
    margin: 6px;
}

.step1 {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    font-size: 0.75rem;
    justify-content: center;
    height: 24px;
    min-width: 24px;
    width: 24px;
    transition: 0.3s;
}

.step2 {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    font-size: 0.75rem;
    justify-content: center;
    height: 24px;
    min-width: 24px;
    width: 24px;
    transition: 0.3s
}

.step3 {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    font-size: 0.75rem;
    justify-content: center;
    height: 24px;
    min-width: 24px;
    width: 24px;
    transition: 0.3s
}

.inputAlignedDown {
    margin-top: 2.5rem !important;
}

.inputFullLine {
    width: 100% !important;
}

.modalW600 {
    padding: 0px !important;
    width: 90% !important;
    max-width: 800px !important;
    margin: 0 auto !important;
    overflow: auto !important;
}


@media screen and (max-width: 600px) {
    .modalW600 {
        width: 100% !important;
    }

}

.container-FileDrop {
    width: 30% !important;
    padding: 20px;
    //margin-top: 20px;
    background-color: #ffff;
    //padding: 1.5rem;
    //border: 1px solid rgba(0, 0, 0, 0.2) !important;

    h2,
    h4 {
        text-transform: uppercase;
        font-weight: 600;
    }
}