@import '@scuf/styles/variables.scss';
@import '@scuf/common/honeywell/theme.css';

.NumbersPersonal {
    width: 100% !important;

    .sidebar-layout {
        width: auto !important;
    }
}

.header {
    text-align: center;
}

.container-input-Large {
    width: 95% !important;
    margin: 10px;
    background-color: red !important;

    .input-label-message {
        width: 100% !important;
    }
}

.input-wrap {
    width: 100% !important;

    .input-box {
        width: 100% !important;
    }
}

.container-input-dual {
    width: 100% !important;

    .ui.single-input {
        width: 45% !important;
        margin: 10px;
    }

    .input-label-message {
        width: 100% !important;
    }

    .input-wrap {
        width: 100% !important;

        .input-box {
            width: 100% !important;
        }
    }
}

.carouselcart {
    height: 150px !important;
    width: 150px !important;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;

    .carousel-image {

        background-size: contain !important;
        background-position: center !important;
        background-repeat: no-repeat !important;

    }

    .carousel-core {
        .carousel-prev {
            width: 0px;
            height: 0px;
            font-size: 12px;

            .sc-bdVaJa {
                color: black;
                font-weight: bold;
            }
        }

        .carousel-next {
            .sc-bdVaJa {
                color: black;
                font-weight: bold;
            }

            width: 0px;
            height: 0px;
            font-size: 12px;
        }

        .slide-markers {
            width: 0px;
            height: 0px;

            .slide-marker-item {
                width: 0px;
                height: 0px;
            }
        }
    }
}

.imagepd {
    width: 150px;
    height: 150px;
}

/*----------------------------------------------------------*/

.overlay-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.carousel-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80%;
    max-height: 80%;
}

.image-carousel {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
    border: 2px solid white;
    border-radius: 8px;
}

.carousel-controls {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
}

button {
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 2rem;
    color: black;
}

button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

button:hover:not(:disabled) {
    background-color: rgba(255, 255, 255, 1);
}

.chevron-left {
    position: absolute;
    left: 20px;
}

.chevron-right {
    position: absolute;
    right: 20px;
}


.WarningNotImage {
    background-color: rgba(48, 48, 48, .6);
    padding: 20px 30px;
    box-shadow: 0px 1px 15px 0px rgba(245, 245, 245, 0.253);
    font-size: 25px;
    font-weight: 500;
    color: whitesmoke;
}

.disabled-chat {
    color: rgb(153, 152, 152);
}

/*--------------------------------------------------------*/