.single-line-icon {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
}

.single-input {
    width: 100%;
    padding: 10px;
    .input-wrap {
        width: 100%;
        .input-box {
            width: 100%;
        }
    }
}
